<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Registro Quantidade de Funcionários / {{ $route.params.id ? 'Editar' : 'Novo' }}
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
                <div class="px-5 pb-4">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="col-span-12 md:col-span-4">
                            <label for="obra" class="block text-sm font-medium">Obra</label>
                            <select v-model="form.obra" id="obra" name="obra" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option v-for="obra in obras" :key="obra._id" :value="obra._id" ><span v-if="$store.state.user.root">{{ obra.empresa.nome }} - </span>{{ obra.nome }}</option>
                            </select>
                        </div> 
                        <div class="col-span-3">
                             <label for="mes" class="block text-sm font-medium">Mês da leitura</label>
                             <select v-model="form.mes" id="mes" name="mes" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option :value="1">Janeiro</option>
                                <option :value="2">Fevereiro</option>
                                <option :value="3">Março</option>
                                <option :value="4">Abril</option>
                                <option :value="5">Maio</option>
                                <option :value="6">Junho</option>
                                <option :value="7">Julho</option>
                                <option :value="8">Agosto</option>
                                <option :value="9">Setembro</option>
                                <option :value="10">Outubro</option>
                                <option :value="11">Novembro</option>
                                <option :value="12">Dezembro</option>
                             </select>
                        </div>
                        <div class="col-span-3">
                             <label for="ano" class="block text-sm font-medium">Ano da leitura</label>
                             <select v-model="form.ano" id="ano" name="ano" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option v-for="idx in 6" :key="idx - 6" :value="$moment().subtract(6 - idx, 'years').format('YYYY')">{{ $moment().subtract(6 - idx, 'years').format('YYYY') }}</option>
                                <option v-for="idx in 5" :key="idx" :value="$moment().add(idx, 'years').format('YYYY')">{{ $moment().add(idx, 'years').format('YYYY') }}</option>
                             </select>
                        </div>
                        <div class="col-span-12 md:col-span-2">
                            <label for="valorAgua" class="block text-sm font-medium">Quantidade</label>
                            <input v-model="form.qtd" type="number" name="valorAgua" id="valorAgua" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2 grap-6">
                    <div class="px-5 py-3 text-left sm:px-6">
                        <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="px-5 py-3 text-right sm:px-6">
                        <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                            Salvar
                        </button>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            route: 'qtdfunc',
            form: {
                obra: null,
                qtd: 0,
                mes: parseInt(this.$moment().format('MM')),
                ano: this.$moment().format('YYYY')
            },
            obras: [],
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back()
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }

        const reqobras = await this.$http.post('/v1/obras/list');
        this.obras = reqobras.data.data;
    },
}
</script>